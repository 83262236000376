import axios, { AxiosInstance } from 'axios';

import { getHostLocation } from 'core/utils/browser/locationUtilsBrowser';

import {
  AbsorbHosts,
  CinderellaHosts,
  KeycloakHosts,
  ProfileAPIHosts,
  ProfileAppHosts,
} from 'core/types/common/hostsTypesCommon';

export const getKeycloakHostByProfileAppHost = (profileAppHost: string) => {
  switch (profileAppHost) {
    case ProfileAppHosts.dev: return KeycloakHosts.dev;
    case ProfileAppHosts.lab: return KeycloakHosts.lab;
    case ProfileAppHosts.sbx: return KeycloakHosts.sbx;
    case ProfileAppHosts.test: return KeycloakHosts.test;
    case ProfileAppHosts.staging: return KeycloakHosts.staging;
    case ProfileAppHosts.prod: return KeycloakHosts.prod;
    default: return KeycloakHosts.dev;
  }
}

export const getProfileAPIHostByProfileAppHost = (profileAppHost: string) => {
  switch (profileAppHost) {
    case ProfileAppHosts.dev: return ProfileAPIHosts.dev;
    case ProfileAppHosts.lab: return ProfileAPIHosts.lab;
    case ProfileAppHosts.sbx: return ProfileAPIHosts.sbx;
    case ProfileAppHosts.test: return ProfileAPIHosts.test;
    case ProfileAppHosts.staging: return ProfileAPIHosts.staging;
    case ProfileAppHosts.prod: return ProfileAPIHosts.prod;
    default: return ProfileAPIHosts.dev;
  }
}

export const getCinderellaHostByProfileAppHost = (profileAppHost: string) => {
  switch (profileAppHost) {
    case ProfileAppHosts.dev: return CinderellaHosts.dev;
    case ProfileAppHosts.lab: return CinderellaHosts.lab;
    case ProfileAppHosts.sbx: return CinderellaHosts.sbx;
    case ProfileAppHosts.test: return CinderellaHosts.test;
    case ProfileAppHosts.staging: return CinderellaHosts.staging;
    case ProfileAppHosts.prod: return CinderellaHosts.prod;
    default: return CinderellaHosts.dev;
  }
};

export const getAbsorbHostByProfileAppHost = (profileAppHost: string) => {
  switch (profileAppHost) {
    case ProfileAppHosts.dev: return AbsorbHosts.dev;
    case ProfileAppHosts.lab: return AbsorbHosts.lab;
    case ProfileAppHosts.sbx: return AbsorbHosts.sbx;
    case ProfileAppHosts.test: return AbsorbHosts.test;
    case ProfileAppHosts.staging: return AbsorbHosts.staging;
    case ProfileAppHosts.prod: return AbsorbHosts.prod;
    default: return AbsorbHosts.dev;
  }
};

export const getWebinarsHostByProfileAppHost = (profileAppHost: string) => {
  switch (profileAppHost) {
    case ProfileAppHosts.dev: return CinderellaHosts.dev;
    case ProfileAppHosts.lab: return CinderellaHosts.lab;
    case ProfileAppHosts.sbx: return CinderellaHosts.sbx;
    case ProfileAppHosts.test: return CinderellaHosts.test;
    case ProfileAppHosts.staging:
    case ProfileAppHosts.prod: return CinderellaHosts.prod;
    default: return CinderellaHosts.dev;
  }
};

export const getProfileAPIUrl = () => {
  const host = getHostLocation();
  return `https://${getProfileAPIHostByProfileAppHost(host)}/profile/v1`;
}

export const getKeycloakUrl = () => {
  const host = getHostLocation();
  return `https://${getKeycloakHostByProfileAppHost(host)}`;
}

export const getOTPAPIUrl = () => {
  return `${getKeycloakUrl()}/otp/v1`;
}

export const getSettingsAPIUrl = () => {
  const host = getHostLocation();
  return `https://${getProfileAPIHostByProfileAppHost(host)}/settings/v1`;
}

const cloneInterceptors = (sourceClient: AxiosInstance, targetClient: AxiosInstance) => {
  sourceClient.interceptors.request.forEach(
    (interceptor) => {
      const { fulfilled, rejected, ...options } = interceptor;
      targetClient.interceptors.request.use(fulfilled, rejected, options);
    }
  );
  sourceClient.interceptors.response.forEach(
    ({ fulfilled, rejected, ...options }) => {
      targetClient.interceptors.response.use(fulfilled, rejected, options);
    }
  );
};

export const cloneApiClient = (apiClient: AxiosInstance) => {
  const { headers, ...config } = apiClient.defaults;
  const clonedClient = axios.create(config);
  clonedClient.defaults.headers = headers;
  cloneInterceptors(apiClient, clonedClient);
  return clonedClient;
};

export const getFormDataFromObject = (object: {[key: string] : string}) => {
  let formData = new FormData();
  
  Object.keys(object).forEach(key => {
    const value = object[key];
    if (value) {
      formData.append(key, value);
    }
  });
  
  return formData;
};